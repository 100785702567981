import React from 'react'

import { FormattedMessage } from 'react-intl'

import styles from './Label.module.css'

interface Props {
  bold?: boolean
  message: string
}

function Label({ message, bold }: Props) {
  return (
    <div className={bold ? styles.bold : styles.light}>
      <FormattedMessage id={message} />
      {bold && '\u00A0:\u00A0'}
    </div>
  )
}

Label.defaultProps = { bold: false }

export default Label
