import React from 'react'

import { Grid, Card as MuiCard } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Label from 'components/label'
import styles from 'screens/home/home.module.css'
import { config, Restitution } from 'types/common.types'

interface Props {
  configuration: Array<config>
  restit: Restitution
  title?: string
  bold?: boolean
}

function Card({ configuration, restit, title, bold = false }: Props) {
  return (
    <MuiCard variant="outlined" sx={{ height: '100%', position: 'relative', overflow: 'initial' }}>
      {title && (
        <span className={styles['section-title']}>
          <FormattedMessage id={title} />
        </span>
      )}
      <Grid container pt="8px">
        {configuration.map((h: config) => (
          <Grid item container xs={12} sm={6} key={h.key}>
            <div className={`${styles.label} ${!bold ? styles.col : ''}`}>
              <Label message={`label.${h.key}`} bold={bold} />
              {h.format(restit) ?? '-'}
            </div>
          </Grid>
        ))}
      </Grid>
    </MuiCard>
  )
}

export default Card
