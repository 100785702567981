import React, { useEffect, useRef, useState } from 'react'

import { Box, Button, Card as MuiCard, CircularProgress, Dialog, Grid, GridSize, Grow, Paper, styled, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'

import 'assets/styles/common.css'
import api from 'api/api'
import logo from 'assets/images/logo-desktop.png'
import cStyles from 'assets/styles/common.module.css'
import { fonts } from 'assets/styles/fonts'
import Card from 'components/card'
import Label from 'components/label'
import Expertise from 'components/svg/Expertise'
import ExpertiseVU from 'components/svg/ExpertiseVU'
import { SubTitle, Title } from 'components/title/Title'
import { COLORS } from 'enums/style.enums'
import { Restitution } from 'types/common.types'
import { DOCUMENT_STATUS, VEHICLE_TYPE } from 'types/enums.types'

import BasicTable from './Array'
import { header, restitution, tenant, vehicle } from './home.config'
import styles from './home.module.css'
import MechanicalDamagesTable from './MechanicalDamagesTable'
import PlateModal from './PlateModal'

export const MContainer = styled(Paper)({
  backgroundColor: 'transparent',
  zIndex: 10,
  boxShadow: 'none',
  overflow: 'hidden',
  minWidth: '30%',
})

const amoviblesKeys: Array<keyof Restitution> = [
  'registrationCard',
  'duplicateKeys',
  'userManual',
  'domesticChargingCable',
  'fastChargingCable',
  'tools',
  'airCompressor',
  'antenna',
  'luggageCover',
]

type Amovible = Record<DOCUMENT_STATUS, string[]>

function Home() {
  const minHeight = useRef(10000)

  const [restit, setRestit] = useState<Restitution | null>(null)
  const [amovibles, setAmovibles] = useState<Amovible | undefined>(undefined)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [licensePlateModal, setLicensePlateModal] = useState(true)
  const [photos, setPhotos] = useState<string[]>([])
  const [photosLoaded, setPhotosLoaded] = useState<number>(0)
  const [photoErrors, setPhotoErrors] = useState<Record<string, boolean>>({})

  const { executeRecaptcha } = useGoogleReCaptcha()
  const params = useParams()
  const intl = useIntl()
  const { id } = params

  const noPhotos = Object.values(photoErrors).reduce((acc, cur) => (cur ? acc + 1 : acc), 0) === restit?.photos?.length
  const restitutionLoadedPhotos = restit?.photos?.filter(p => !photoErrors[p])

  const { enqueueSnackbar } = useSnackbar()

  const loadImage = (imageUrl: string) => {
    const img = new Image()
    img.src = imageUrl

    img.onload = () => {
      setPhotosLoaded(prev => prev + 1)
    }

    img.onerror = () => {
      setPhotosLoaded(prev => prev + 1)
      setPhotoErrors(prev => ({ ...prev, [imageUrl]: true }))
    }
  }

  useEffect(() => {
    if (restit) {
      restit?.photos?.forEach(p => loadImage(p))
    }
  }, [restit])

  const onClickPhoto = (photos: string[]) => {
    setPhotos(photos)
    setModal(true)
  }

  const onConfirmLicensePlate = async (licensePlate: string) => {
    setLoading(true)
    try {
      if (!executeRecaptcha) {
        enqueueSnackbar(intl.formatMessage({ id: 'error.recaptcha' }), { variant: 'error' })
        return
      }

      const recaptchaToken = await executeRecaptcha('IRIS')
      const { data } = await api.public.restitution({ licensePlate, recaptchaToken, id: id ?? '' })
      const a: Amovible = {
        [DOCUMENT_STATUS.PRESENT]: [],
        [DOCUMENT_STATUS.NOT_DELIVERED]: [],
        [DOCUMENT_STATUS.ABSENT]: [],
      }

      amoviblesKeys.forEach(k => {
        if (data[k] === DOCUMENT_STATUS.PRESENT) {
          a[DOCUMENT_STATUS.PRESENT].push(k)
        }
        if (data[k] === DOCUMENT_STATUS.ABSENT) {
          a[DOCUMENT_STATUS.ABSENT].push(k)
        }
        if (data[k] === DOCUMENT_STATUS.NOT_DELIVERED) {
          a[DOCUMENT_STATUS.NOT_DELIVERED].push(k)
        }
      })

      setAmovibles(a)

      setRestit(data)

      setLicensePlateModal(false)
    } catch (e) {
      const type = (e as any)?.response?.data?.error

      enqueueSnackbar(intl.formatMessage({ id: `error.${type ?? 'default'}` }), { variant: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  if (!restit) {
    return (
      <div className={cStyles['centered-container']}>
        {loading && (
          <div className={styles.fixed}>
            <CircularProgress size={64} />
          </div>
        )}
        <PlateModal open={licensePlateModal} onConfirm={onConfirmLicensePlate} />
      </div>
    )
  }

  return (
    <div className={styles.main}>
      <Dialog onClose={() => setModal(false)} open={modal} PaperComponent={MContainer}>
        <Slider {...settings}>
          {photos &&
            photos.map((p, i) => (
              <div className={styles['modal-container']} key={p + i}>
                <img alt="restitution" className={styles['modal-photo']} src={p} />
              </div>
            ))}
        </Slider>
      </Dialog>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <img src={logo} alt="diac-logo" />
            </Grid>
            {restit.pvUrl && (
              <Grid item container xs={6} alignItems="flex-end" justifyContent="flex-end">
                <a href={restit.pvUrl} target="_blank" className={styles.link} rel="noreferrer">
                  <Button variant="contained">
                    <Typography fontFamily={fonts.bold} fontSize={12} color="white">
                      {intl.formatMessage({ id: 'screen.details.pvLink' })}
                    </Typography>
                  </Button>
                </a>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <Card configuration={header} restit={restit} bold />
            </Grid>
            <Grid item xs={12}>
              <Card configuration={tenant} restit={restit} title="title.tenant" />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} pb={2}>
            <Grid container item xs={12} spacing={2}>
              {!noPhotos && photosLoaded >= restit?.photos?.length && (
                <Grid item xs={12} mb={1} height="fit-content" sx={{ maxHeight: minHeight.current }}>
                  <Slider {...settings} draggable={false}>
                    {restitutionLoadedPhotos &&
                      restitutionLoadedPhotos.map(p => (
                        <div key={p}>
                          <div className={styles['photo-container']} style={{ height: minHeight.current }}>
                            <img
                              src={p}
                              className={styles['main-img']}
                              alt="vehicle"
                              onClick={() => {
                                onClickPhoto(restitutionLoadedPhotos)
                              }}
                              onLoad={(e: any) => {
                                if (e?.target?.offsetHeight < minHeight.current) {
                                  minHeight.current = e?.target?.offsetHeight
                                  // trigger re-render to update size
                                  setPhotosLoaded(prev => prev + 1)
                                }
                              }}
                              style={{ maxHeight: minHeight.current }}
                            />
                          </div>
                        </div>
                      ))}
                  </Slider>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <Card configuration={restitution} restit={restit} title="title.restitution" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card configuration={vehicle} restit={restit} title="title.vehicle" />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grow in mountOnEnter unmountOnExit>
            <MuiCard variant="outlined" sx={{ marginLeft: '16px', marginTop: '16px', width: '100%', padding: '16px' }}>
              <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Grid item container xs={12} pb={1}>
                      <Title>
                        <FormattedMessage id="label.generalCondition" />
                      </Title>
                    </Grid>
                    <MuiCard variant="outlined">
                      <Grid container>
                        <Grid item container xs={6}>
                          <div className={`${styles.label} ${styles.col}`}>
                            <Label message="label.generalCondition" />
                            <FormattedMessage id={`restitution.generalCondition.${restit.generalCondition}`} />
                          </div>
                        </Grid>
                        <Grid item container xs={6}>
                          <div className={`${styles.label} ${styles.col}`}>
                            <Label message="label.mileage" />
                            <FormattedNumber value={restit.mileage} style="unit" unit="kilometer" unitDisplay="short" />
                          </div>
                        </Grid>
                        <Grid item container xs={6}>
                          <div className={`${styles.label} ${styles.col}`}>
                            <Label message="label.cleanlinessInt" />
                            {restit.cleanlinessInt ? <FormattedMessage id={`label.cleanliness.${restit.cleanlinessInt}`} /> : '-'}
                          </div>
                        </Grid>
                        <Grid item container xs={6}>
                          <div className={`${styles.label} ${styles.col}`}>
                            <Label message="label.cleanlinessExt" />
                            {restit.cleanlinessExt ? <FormattedMessage id={`label.cleanliness.${restit.cleanlinessExt}`} /> : '-'}
                          </div>
                        </Grid>
                        <Grid item container xs={6}>
                          <div className={`${styles.label} ${styles.col}`}>
                            <Label message="label.tireSeason" />
                            {restit.tireSeason ? <FormattedMessage id={`label.tireSeason.${restit.tireSeason}`} /> : '-'}
                          </div>
                        </Grid>
                        <Grid item container xs={6}>
                          <div className={`${styles.label} ${styles.col}`}>
                            <Label message="label.tireBrand" />
                            {restit.tireBrand ?? '-'}
                          </div>
                        </Grid>
                        <Grid item container xs={6}>
                          <div className={`${styles.label} ${styles.col}`}>
                            <Label message="label.tireSize" />
                            {restit.tireSize ?? '-'}
                          </div>
                        </Grid>
                      </Grid>
                    </MuiCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={styles['container-img']}>
                      {restit.type === VEHICLE_TYPE.VU ? (
                        <ExpertiseVU className={styles['fluid-img']} damages={restit.damages || []} />
                      ) : (
                        <Expertise className={styles['fluid-img']} damages={restit.damages || []} />
                      )}
                    </div>
                  </Grid>
                  {amovibles && (
                    <Grid item container xs={12} pb={1}>
                      <Title>
                        <FormattedMessage id="screen.details.amovibles" />
                      </Title>
                      <Grid item container xs={12} spacing={1} pt={2}>
                        {Object.keys(amovibles).map(k => (
                          <Grid key={k} item container xs={12} md={(12 / Object.keys(amovibles).length) as GridSize}>
                            <Grid item container xs={12} sx={{ backgroundColor: COLORS.GREY_LIGHT }} p={1}>
                              <Grid item container xs={12} height="fit-content">
                                <Grid item xs={12} p={1}>
                                  <SubTitle>
                                    <FormattedMessage id={`screen.details.amovibles.${k}`} />
                                  </SubTitle>
                                </Grid>
                                <Grid item xs={12} p={1} pt={0}>
                                  {amovibles[k as DOCUMENT_STATUS].map((v: string) => (
                                    <div key={v}>{`- ${intl.formatMessage({ id: `restitution.${v}` })}`}</div>
                                  ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <BasicTable restitution={restit} damages={restit?.damages} intl={intl} onClickPhoto={onClickPhoto} />
                  </Grid>
                </Grid>
                {restit?.mechanicalDamages && (
                  <Grid item container xs={12} pl={3} pr={3} pt={2} pb={2}>
                    <Grid item container xs={12} mb={1} mt={2}>
                      <Box mb={1}>
                        <Title>
                          <FormattedMessage id="restitution.mechanicalDamages" />
                        </Title>
                      </Box>
                      <MechanicalDamagesTable mechanicalDamages={restit.mechanicalDamages} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </MuiCard>
          </Grow>
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
