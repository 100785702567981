import { CLEANLINESS, DamageZones, DOCUMENT_STATUS, GENERAL_CONDITION, TIRE_SEASON } from 'types/enums.types'

const common = {
  'common.notFound': 'La page est introuvable !',
  'error.default': 'Une erreur est survenue !',
  'error.INVALID_CREDENTIALS': "L'immatriculation est incorrecte !",
  'error.LOCKED_RESTIT': 'Vous avez trop de tentatives sans succès, veuillez réessayer dans quelques minutes !',
  'error.recaptcha': 'Erreur, veuillez recharger la page !',
  'error.NOT_FOUND': "La restitution n'existe pas !",
  'modal.plate.title': "Veuillez renseigner l'immatriculation du véhicule",
  'button.confirm': 'Confirmer',
  'label.brand': 'Marque',
  'label.buyer': 'Repreneur',
  'label.color': 'Couleur extérieur',
  'label.contractId': 'Numéro de contrat',
  'label.chassis': 'Chassis',
  'label.cleanliness': 'Propreté du véhicule',
  'label.cleanlinessInt': 'Propreté intérieure',
  'label.cleanlinessExt': 'Propreté extérieure',
  'label.domesticChargingCable': 'Cable de charge domestique',
  [`label.domesticChargingCable.${DOCUMENT_STATUS.PRESENT}`]: 'Présent',
  [`label.domesticChargingCable.${DOCUMENT_STATUS.ABSENT}`]: 'Non remis à la livraison',
  [`label.domesticChargingCable.${DOCUMENT_STATUS.NOT_DELIVERED}`]: 'Absent',
  'label.fastChargingCable': 'Cable de charge rapide',
  [`label.fastChargingCable.${DOCUMENT_STATUS.PRESENT}`]: 'Présent',
  [`label.fastChargingCable.${DOCUMENT_STATUS.ABSENT}`]: 'Non remis à la livraison',
  [`label.fastChargingCable.${DOCUMENT_STATUS.NOT_DELIVERED}`]: 'Absent',
  [`label.registrationCard.${DOCUMENT_STATUS.PRESENT}`]: 'Présente',
  [`label.registrationCard.${DOCUMENT_STATUS.ABSENT}`]: 'Non remise à la livraison',
  [`label.registrationCard.${DOCUMENT_STATUS.NOT_DELIVERED}`]: 'Absente',
  [`label.duplicateKeys.${DOCUMENT_STATUS.PRESENT}`]: 'Présent',
  [`label.duplicateKeys.${DOCUMENT_STATUS.ABSENT}`]: 'Non remis à la livraison',
  [`label.duplicateKeys.${DOCUMENT_STATUS.NOT_DELIVERED}`]: 'Absent',
  [`label.userManual.${DOCUMENT_STATUS.PRESENT}`]: 'Présent',
  [`label.userManual.${DOCUMENT_STATUS.ABSENT}`]: 'Non remis à la livraison',
  [`label.userManual.${DOCUMENT_STATUS.NOT_DELIVERED}`]: 'Absent',
  [`label.cleanliness.${CLEANLINESS.TODO}`]: 'À faire',
  [`label.cleanliness.${CLEANLINESS.CLEAN}`]: 'Propre',
  'label.date': 'Date de la restitution',
  'label.duplicateKeys': 'Double des clés',
  'label.contractType': 'Type de contrat',
  'label.expertCompany': 'Expertise',
  'label.firstRegistrationDate': 'Date de 1er MEC',
  'label.fuel': 'Carburant',
  'label.generalCondition': 'État général',
  [`restitution.generalCondition.${GENERAL_CONDITION.CONFORME}`]: 'Conforme',
  [`restitution.generalCondition.${GENERAL_CONDITION.DAMAGED}`]: 'Endommagé',
  [`restitution.generalCondition.${GENERAL_CONDITION.UNKNOWN}`]: 'Inconnu',
  [`restitution.generalCondition.${GENERAL_CONDITION.HAIL}`]: 'Grêlé',
  'label.licensePlate': 'Immatriculation',
  'label.mileage': 'Kilométrage réel',
  'label.model': 'Modèle',
  'label.nbPlaces': 'Nombre de places',
  'label.receptionPlace': 'Lieux de la restitution',
  'label.receptionPlaceAddress': 'Adresse de la restitution',
  'label.registrationCard': 'Carte Grise',
  'label.restitutionDate': 'Date de restitution',
  'label.storagePlace': 'Lieu de stockage',
  'label.tenant.name': 'Locataire',
  'label.tenant.siret': 'SIRET',
  'label.tenant.address': 'Adresse',
  'label.tireSeason': 'Saison des pneus',
  'label.tireBrand': 'Marque des pneus',
  'label.tireSize': 'Taille des pneus',
  [`label.tireSeason.${TIRE_SEASON.WINTER}`]: 'Hiver',
  [`label.tireSeason.${TIRE_SEASON.SUMMER}`]: 'Été',
  [`label.tireSeason.${TIRE_SEASON.ALL_SEASON}`]: 'Toutes saison',
  'label.type': 'Genre',
  'label.visualId': 'Identifiant',
  'label.userManual': "Manuel d'utilisation",
  'title.restitution': 'Restitution',
  'title.tenant': 'Locataire',
  'title.vehicle': 'Véhicule',
  'screen.details.amovibles': 'Amovibles',
  [`screen.details.amovibles.${DOCUMENT_STATUS.ABSENT}`]: 'Absent',
  [`screen.details.amovibles.${DOCUMENT_STATUS.NOT_DELIVERED}`]: 'Non remis à la livraison',
  [`screen.details.amovibles.${DOCUMENT_STATUS.PRESENT}`]: 'Présent',
  'screen.details.pvLink': 'PV de restitution',
  'restitution.registrationCard': 'Carte grise',
  'restitution.airCompressor': 'Compresseur à air',
  'restitution.tools': 'Outils',
  'restitution.antenna': 'Antenne',
  'restitution.luggageCover': 'Cache bagage',
  'restitution.userManual': "Manuel d'utilisation",
  'restitution.duplicateKeys': 'Double des clés',
  'restitution.fastChargingCable': 'Cable de charge rapide',
  'restitution.contractType.L10': 'DLA Reprise réseau',
  'restitution.contractType.L20': 'DLO reprise constructeur',
  'restitution.contractType.L2R': 'Reprise Réseau avec expertise Dekra',
  'restitution.contractType.LDER': 'LDER',
  'restitution.contractType.LPER': 'LPER',
  'restitution.domesticChargingCable': 'Cable de charge domestique',
  'restitution.damages.operation': 'Operation',
  'restitution.damages.partPrice': 'Prix pièce',
  'restitution.damages.estimatedPrice': 'Montant évalué',
  'restitution.damages.bodyDuration': 'Temps carrosserie',
  'restitution.damages.damage': 'Dommage',
  'restitution.damages.element': 'Elément',
  'restitution.damages.obsolescence': 'Vétusté',
  'restitution.damages.position': 'Position',
  'restitution.damages.paintDuration': 'Temps peinture',
  'restitution.damages.photos': 'Photos',
  'restitution.damages.severity': 'Gravité',
  'restitution.damages.totalPrice': 'Montant après vétusté',
  'restitution.mechanicalDamages': 'Problème(s) mécanique(s)',
  'restitution.mechanicalDamages.comment': 'Commentaire',
  'restitution.mechanicalDamages.element': 'Elément',
  [`zone.${DamageZones.LEFT_REAR_WHEEL}`]: 'Roue arrière gauche',
  [`zone.${DamageZones.LEFT_REAR_FENDER}`]: 'Aile arrière gauche',
  [`zone.${DamageZones.LEFT_REAR_DOOR}`]: 'Portière arrière gauche',
  [`zone.${DamageZones.LEFT_REAR_WINDOW}`]: 'Vitre arrière gauche',
  [`zone.${DamageZones.LEFT_FRONT_WINDOW}`]: 'Vitre avant gauche',
  [`zone.${DamageZones.LEFT_FRONT_DOOR}`]: 'Portière avant gauche',
  [`zone.${DamageZones.LEFT_SIDE_SILL}`]: 'Bas de caisse gauche',
  [`zone.${DamageZones.LEFT_FRONT_FENDER}`]: 'Aile avant gauche',
  [`zone.${DamageZones.LEFT_FRONT_WHEEL}`]: 'Roue avant gauche',
  [`zone.${DamageZones.LEFT_REAR_VIEW_MIRROR}`]: 'Rétroviseur gauche',
  [`zone.${DamageZones.REAR_BUMPER}`]: 'Pare-choc arrière',
  [`zone.${DamageZones.LEFT_REAR_HEADLIGHT}`]: 'Feu arrière gauche',
  [`zone.${DamageZones.REAR_LICENSE_PLATE}`]: "Plaque d'immatriculation arrière",
  [`zone.${DamageZones.RIGHT_REAR_HEADLIGHT}`]: 'Feu arrière droit',
  [`zone.${DamageZones.TRUNK}`]: 'Coffre',
  [`zone.${DamageZones.REAR_WINDSHIELD}`]: 'Pare-brise arrière',
  [`zone.${DamageZones.ROOF}`]: 'Toit',
  [`zone.${DamageZones.FRONT_WINDSHIELD}`]: 'Pare-brise avant',
  [`zone.${DamageZones.HOOD}`]: 'Capot',
  [`zone.${DamageZones.LEFT_FRONT_HEADLIGHT}`]: 'Feu avant gauche',
  [`zone.${DamageZones.GRILL}`]: 'Grille',
  [`zone.${DamageZones.FRONT_RIGHT_HEADLIGHT}`]: 'Feu avant droit',
  [`zone.${DamageZones.LEFT_FRONT_BUMPER}`]: 'Bouclier avant gauche',
  [`zone.${DamageZones.FRONT_BUMPER}`]: 'Pare-choc avant',
  [`zone.${DamageZones.RIGHT_FRONT_BUMPER}`]: 'Bouclier avant droit',
  [`zone.${DamageZones.RIGHT_REAR_VIEW_MIRROR}`]: 'Rétroviseur droit',
  [`zone.${DamageZones.SPARE_WHEEL}`]: 'Roue de secours',
  [`zone.${DamageZones.RIGHT_REAR_FENDER}`]: 'Aile arrière droite',
  [`zone.${DamageZones.RIGHT_REAR_WHEEL}`]: 'Roue arrière droite',
  [`zone.${DamageZones.RIGHT_REAR_DOOR}`]: 'Portière arrière droite',
  [`zone.${DamageZones.RIGHT_REAR_WINDOW}`]: 'Vitre arrière droite',
  [`zone.${DamageZones.FRONT_RIGHT_WINDOW}`]: 'Vitre avant droite',
  [`zone.${DamageZones.RIGHT_FRONT_DOOR}`]: 'Portière avant droite',
  [`zone.${DamageZones.RIGHT_ROCKER_PANEL}`]: 'Bas de caisse droit',
  [`zone.${DamageZones.RIGHT_FRONT_FENDER}`]: 'Aile avant droite',
  [`zone.${DamageZones.RIGHT_FRONT_WHEEL}`]: 'Roue avant droite',
  [`zone.${DamageZones.UNDEFINED}`]: 'Non Définie',
  [`zone.${DamageZones.INSIDE}`]: 'Intérieur',
  [`zone.${DamageZones.RIGHT_REAR_BUMPER}`]: 'Pare-choc/bouclier arrière droit',
  [`zone.${DamageZones.LEFT_REAR_BUMPER}`]: 'Pare-choc/bouclier arrière gauche',
}

export default common
