import { IntlShape } from 'react-intl'

const printDate = (intl: IntlShape, d: number | string | Date) =>
  `${intl.formatDate(new Date(d))} à ${intl.formatDate(new Date(d), { timeStyle: 'medium' })}`

export const formatDateTime = (intl: IntlShape, value?: unknown) => {
  if (typeof value === 'string') {
    if (!value.endsWith('Z')) {
      return printDate(intl, `${value}Z`)
    }
    return printDate(intl, value)
  }
  if (value instanceof Date || typeof value === 'number') {
    return printDate(intl, value)
  }

  return '-'
}

export const formatTime = (time: string | number) => {
  if (!time) {
    return '-'
  }
  const n = new Date(0, 0)
  n.setMinutes(+time * 60)
  return n.toTimeString().slice(0, 5)
}

export const formatDuration = (intl: IntlShape, value: unknown) => {
  if (!value) {
    return '-'
  }

  if (typeof value === 'number' && Number.isFinite(value)) {
    const h = intl.formatNumber(value)

    return `${h} h`
  }

  return '-'
}

export const formatNull = (value?: unknown) => (value ? `${value}` : '-')

export const formatPrice = (intl: IntlShape, value?: unknown) => {
  if (typeof value === 'number' && Number.isFinite(value)) {
    return intl.formatNumber(value, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: value % 1 === 0 ? 0 : 2,
    })
  }

  return '-'
}

export const formatNullNumber = (value?: number) => (value || value === 0 ? `${value}` : '-')
