import React from 'react'

import { createTheme, ThemeProvider } from '@mui/material'
import { grey } from '@mui/material/colors'
import { SnackbarProvider } from 'notistack'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'

import './assets/styles/common.css'
import App from './App'
import { COLORS } from './enums/style.enums'
import fr from './i18n/messages'

const theme = createTheme({
  palette: {
    primary: { main: COLORS.PRIMARY },
    secondary: { main: grey[600] },
    contrastThreshold: 2,
  },
  typography: {
    fontSize: 13,
    fontFamily: ['RCI Book', 'Roboto', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
})

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={fr.locale} messages={fr.messages}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
