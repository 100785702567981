import * as React from 'react'

import { styled, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FormattedMessage, IntlShape } from 'react-intl'
import Slider from 'react-slick'

import { fonts } from 'assets/styles/fonts'
import { COLORS } from 'enums/style.enums'
import { Damage, Restitution } from 'types/common.types'
import { formatDuration, formatNull, formatNullNumber, formatPrice } from 'utils/format.utils'

import styles from './home.module.css'

interface Props {
  damages: Array<Damage>
  restitution: Restitution
  intl: IntlShape
  onClickPhoto: (p: string[]) => void
}

const TableCellTitle = styled(TableCell)(() => ({
  border: 'none',
  fontFamily: 'RCI Bold',
}))

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const keys = [
  'restitution.damages.photos',
  'restitution.damages.element',
  'restitution.damages.position',
  'restitution.damages.damage',
  'restitution.damages.operation',
  'restitution.damages.bodyDuration',
  'restitution.damages.paintDuration',
  'restitution.damages.partPrice',
  'restitution.damages.estimatedPrice',
  'restitution.damages.obsolescence',
  'restitution.damages.totalPrice',
]

function BasicTable({ damages, restitution, intl, onClickPhoto }: Props) {
  return (
    <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {keys.map((k: string) => (
              <TableCellTitle sx={{ fontSize: 12 }} align="left" key={k}>
                <FormattedMessage id={k} />
              </TableCellTitle>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {damages &&
            damages.map(row => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" onClick={() => row.photos && onClickPhoto(row.photos)}>
                  <Slider {...settings} className={styles['small-slider']}>
                    {row?.photos?.map(p => (
                      <div key={p} style={{ width: '100%' }}>
                        <img style={{ width: '100%' }} src={p} alt="vehicle" />
                      </div>
                    ))}
                  </Slider>
                </TableCell>
                <TableCell align="right">{row.element}</TableCell>
                <TableCell>{row.position ? intl.formatMessage({ id: `zone.${row.position}` }) : formatNull(row.position)}</TableCell>
                <TableCell>{formatNull(row.damage)}</TableCell>
                <TableCell>{formatNull(row.operation)}</TableCell>
                <TableCell>{formatDuration(intl, row.bodyDuration)}</TableCell>
                <TableCell>{formatDuration(intl, row.paintDuration)}</TableCell>
                <TableCell>{formatPrice(intl, row.partPrice !== 0 ? row.partPrice : undefined)}</TableCell>
                <TableCell>{formatPrice(intl, row.estimatedPrice)}</TableCell>
                <TableCell>{`${formatNullNumber(row.obsolescence)}${row.obsolescence || row.obsolescence === 0 ? ' %' : ''}`}</TableCell>
                <TableCell>{formatPrice(intl, row.totalPrice)}</TableCell>
              </TableRow>
            ))}
          {Number.isFinite(restitution.totalDamages) && (
            <TableRow sx={{ backgroundColor: COLORS.GREY_BG }}>
              <TableCell>
                <Typography fontFamily={fonts.bold}>Total HT</Typography>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                <Typography fontFamily={fonts.bold}>
                  {formatPrice(
                    intl,
                    restitution.damages?.reduce((prev, cur) => prev + (cur.estimatedPrice || 0), 0)
                  )}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell>
                <Typography fontFamily={fonts.bold}>{formatPrice(intl, restitution.totalDamages)}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BasicTable
