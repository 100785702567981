export enum CONFIG {
  CF_HOST = 'CF_HOST',
  API_BASE_HOSTNAME = 'API_BASE_HOSTNAME',
  API_PUBLIC_BASE_URL = 'API_PUBLIC_BASE_URL',
  API_GATEWAY_KEY_PUBLIC = 'API_GATEWAY_KEY_PUBLIC',
  RECAPTCHA_KEY = 'RECAPTCHA_KEY',
  CF_FILE = 'CF_FILE',
  ENV = 'ENV',
}

export enum DamageZones {
  'LEFT_REAR_WHEEL' = 'LEFT_REAR_WHEEL',
  'LEFT_REAR_FENDER' = 'LEFT_REAR_FENDER',
  'LEFT_REAR_DOOR' = 'LEFT_REAR_DOOR',
  'LEFT_REAR_WINDOW' = 'LEFT_REAR_WINDOW',
  'LEFT_FRONT_WINDOW' = 'LEFT_FRONT_WINDOW',
  'LEFT_FRONT_DOOR' = 'LEFT_FRONT_DOOR',
  'LEFT_SIDE_SILL' = 'LEFT_SIDE_SILL',
  'LEFT_FRONT_FENDER' = 'LEFT_FRONT_FENDER',
  'LEFT_FRONT_WHEEL' = 'LEFT_FRONT_WHEEL',
  'LEFT_REAR_VIEW_MIRROR' = 'LEFT_REAR_VIEW_MIRROR',
  'REAR_BUMPER' = 'REAR_BUMPER',
  'LEFT_REAR_HEADLIGHT' = 'LEFT_REAR_HEADLIGHT',
  'REAR_LICENSE_PLATE' = 'REAR_LICENSE_PLATE',
  'RIGHT_REAR_HEADLIGHT' = 'RIGHT_REAR_HEADLIGHT',
  'TRUNK' = 'TRUNK',
  'REAR_WINDSHIELD' = 'REAR_WINDSHIELD',
  'ROOF' = 'ROOF',
  'FRONT_WINDSHIELD' = 'FRONT_WINDSHIELD',
  'HOOD' = 'HOOD',
  'LEFT_FRONT_HEADLIGHT' = 'LEFT_FRONT_HEADLIGHT',
  'GRILL' = 'GRILL',
  'FRONT_RIGHT_HEADLIGHT' = 'FRONT_RIGHT_HEADLIGHT',
  'LEFT_FRONT_BUMPER' = 'LEFT_FRONT_BUMPER',
  'FRONT_BUMPER' = 'FRONT_BUMPER',
  'RIGHT_FRONT_BUMPER' = 'RIGHT_FRONT_BUMPER',
  'RIGHT_REAR_VIEW_MIRROR' = 'RIGHT_REAR_VIEW_MIRROR',
  'SPARE_WHEEL' = 'SPARE_WHEEL',
  'RIGHT_REAR_FENDER' = 'RIGHT_REAR_FENDER',
  'RIGHT_REAR_WHEEL' = 'RIGHT_REAR_WHEEL',
  'RIGHT_REAR_DOOR' = 'RIGHT_REAR_DOOR',
  'RIGHT_REAR_WINDOW' = 'RIGHT_REAR_WINDOW',
  'FRONT_RIGHT_WINDOW' = 'FRONT_RIGHT_WINDOW',
  'RIGHT_FRONT_DOOR' = 'RIGHT_FRONT_DOOR',
  'RIGHT_ROCKER_PANEL' = 'RIGHT_ROCKER_PANEL',
  'RIGHT_FRONT_FENDER' = 'RIGHT_FRONT_FENDER',
  'RIGHT_FRONT_WHEEL' = 'RIGHT_FRONT_WHEEL',
  'UNDEFINED' = 'UNDEFINED',
  'INSIDE' = 'INSIDE',
  'RIGHT_REAR_BUMPER' = 'RIGHT_REAR_BUMPER',
  'LEFT_REAR_BUMPER' = 'LEFT_REAR_BUMPER',
}

export enum WINDOW_PROPERTIES {
  CONFIGURATION = 'CONFIGURATION',
}

export enum GENERAL_CONDITION {
  CONFORME = 'CONFORME',
  DAMAGED = 'DAMAGED',
  HAIL = 'HAIL',
  UNKNOWN = 'UNKNOWN',
}

export enum CLEANLINESS {
  CLEAN = 'CLEAN',
  TODO = 'TODO',
}

export enum DOCUMENT_STATUS {
  PRESENT = 'PRESENT',
  NOT_DELIVERED = 'NOT_DELIVERED',
  ABSENT = 'ABSENT',
}

export enum TIRE_SEASON {
  ALL_SEASON = 'ALL_SEASON',
  WINTER = 'WINTER',
  SUMMER = 'SUMMER',
}

export enum VEHICLE_TYPE {
  VU = 'VU',
  VP = 'VP',
}

export const CONFIGURATION_FILE = '/config.json'
