import styled from '@emotion/styled'

export const Title = styled.h2`
  font-family: 'RCI Black', -apple-system, 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 1.5em;
  margin: 0;
`

export const SubTitle = styled.h3`
  font-family: 'RCI Bold', -apple-system, 'Roboto', sans-serif;
  font-size: 1.2em;
  margin: 0;
`
