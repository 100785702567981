import React, { useEffect, useState } from 'react'

import './assets/styles/common.css'

import { CircularProgress } from '@mui/material'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Route, Routes } from 'react-router-dom'

import Home from 'screens/home'
import NotFound from 'screens/notFound'

import styles from './assets/styles/common.module.css'
import { getConfiguration, loadConfiguration } from './config/env'
import { CONFIG } from './types/enums.types'

function App(): React.ReactElement {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      try {
        await loadConfiguration()
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  if (loading) {
    return (
      <div className={styles['centered-container']}>
        <CircularProgress size={42} />
      </div>
    )
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={getConfiguration(CONFIG.RECAPTCHA_KEY)}>
      <Routes>
        <Route path="/" element={<NotFound />} />
        <Route path="/:id" element={<Home />} />
      </Routes>
    </GoogleReCaptchaProvider>
  )
}

export default App
