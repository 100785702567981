import axios from 'axios'

import { getConfiguration } from 'config/env'
import { CONFIG } from 'types/enums.types'

import endpoints from './endpoints'
import { Restitution, RestitutionForm } from '../types/common.types'

// 5 minutes
axios.defaults.timeout = 60000 * 5

const ws = axios.create({ maxRedirects: 0 })

const api = {
  public: {
    restitution: (form: RestitutionForm) => ws.post<Restitution>(endpoints.restitution(), form),
  },
}

ws.interceptors.request.use(async request => {
  axios.defaults.baseURL = getConfiguration(CONFIG.API_BASE_HOSTNAME)
  ws.defaults.baseURL = getConfiguration(CONFIG.API_BASE_HOSTNAME)
  request.baseURL = getConfiguration(CONFIG.API_BASE_HOSTNAME)

  if (request.headers) {
    request.headers['x-agent'] = 'IRIS'

    request.headers['x-Gateway-APIKey'] = getConfiguration(CONFIG.API_GATEWAY_KEY_PUBLIC)
  }

  return request
})

ws.interceptors.response.use(
  async response => response,
  async error => {
    if (axios.isCancel(error)) {
      return
    }

    throw error
  }
)

export default api
