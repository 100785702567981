import { Configuration } from 'types/common.types'
import { CONFIG, CONFIGURATION_FILE, WINDOW_PROPERTIES } from 'types/enums.types'

declare global {
  interface Window {
    CONFIGURATION: Configuration
  }
}

window[WINDOW_PROPERTIES.CONFIGURATION] = {
  CF_HOST: '',
  API_BASE_HOSTNAME: '',
  API_PUBLIC_BASE_URL: '',
  API_GATEWAY_KEY_PUBLIC: '',
  RECAPTCHA_KEY: '',
  CF_FILE: '',
  ENV: '',
}

const getConfiguration = (key: keyof Configuration): string => window?.[WINDOW_PROPERTIES.CONFIGURATION]?.[key]

const loadConfiguration = (): Promise<void> =>
  fetch(CONFIGURATION_FILE)
    .then(r => r.json())
    .then(data => {
      Object.values(CONFIG).forEach((k: CONFIG) => {
        window[WINDOW_PROPERTIES.CONFIGURATION][k] = data[k]
      })
    })

export { getConfiguration, loadConfiguration }
