import React from 'react'

import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import styles from 'assets/styles/common.module.css'

function NotFound() {
  return (
    <div className={styles['centered-container']}>
      <Typography variant="h4">404</Typography>
      <Typography variant="subtitle1">Not Found</Typography>
      <Typography variant="subtitle1">
        <FormattedMessage id="common.notFound" />
      </Typography>
    </div>
  )
}

export default NotFound
