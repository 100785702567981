import common from './common.i18n'

const fr = {
  locale: 'fr',
  messages: {
    ...common,
  },
}

export default fr
