import * as React from 'react'

import { styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FormattedMessage } from 'react-intl'

import { MechanicalDamage } from 'types/common.types'
import { formatNull } from 'utils/format.utils'

const keys = ['restitution.mechanicalDamages.element', 'restitution.mechanicalDamages.comment']

const TableCellTitle = styled(TableCell)(() => ({
  border: 'none',
  fontFamily: 'RCI Bold',
}))

interface Props {
  mechanicalDamages?: Array<MechanicalDamage>
}

function MechanicalDamagesTable({ mechanicalDamages }: Props) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            {keys.map((k: string) => (
              <TableCellTitle sx={{ fontSize: 12 }} align="left" key={k}>
                <FormattedMessage id={k} />
              </TableCellTitle>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mechanicalDamages &&
            mechanicalDamages.length > 0 &&
            mechanicalDamages.map((row, i) => (
              <TableRow key={`${row.element}${i}`}>
                <TableCell>{formatNull(row.element)}</TableCell>
                <TableCell>{formatNull(row.comment)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MechanicalDamagesTable
