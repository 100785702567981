import React from 'react'

import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl'

import { config, Restitution } from 'types/common.types'

export const header: Array<config> = [
  {
    key: 'contractId',
    format: (object: Restitution) => object.contractId,
  },
  {
    key: 'visualId',
    format: (object: Restitution) => object.visualId,
  },
  {
    key: 'tenant.name',
    format: (object: Restitution) => object.tenant?.name,
  },
  {
    key: 'restitutionDate',
    format: (object: Restitution) => {
      if (object.date) {
        return <FormattedDate value={`${object.date}Z`} year="numeric" month="numeric" day="numeric" hour="numeric" minute="numeric" />
      }
      return '-'
    },
  },
  {
    key: 'licensePlate',
    format: (object: Restitution) => object.licensePlate,
  },
  {
    key: 'expertCompany',
    format: (object: Restitution) => object.expertCompany,
  },
  {
    key: 'mileage',
    format: (object: Restitution) => <FormattedNumber value={object.mileage} style="unit" unit="kilometer" unitDisplay="short" />,
  },
  {
    key: 'receptionPlace',
    format: (object: Restitution) => object.receptionPlace,
  },
]

export const tenant: Array<config> = [
  {
    key: 'tenant.name',
    format: (object: Restitution) => object.tenant?.name,
  },
  {
    key: 'tenant.siret',
    format: (object: Restitution) => object.tenant?.siret,
  },
  {
    key: 'tenant.address',
    format: (object: Restitution) => object.tenant?.address,
  },
]

export const restitution: Array<config> = [
  {
    key: 'contractId',
    format: (object: Restitution) => object.contractId,
  },
  {
    key: 'contractType',
    format: (object: Restitution) => <FormattedMessage id={`restitution.contractType.${object.contractType}`} />,
  },
  { key: 'buyer', format: (object: Restitution) => object.buyer },
  { key: 'receptionPlace', format: (object: Restitution) => object.receptionPlace },
  { key: 'receptionPlaceAddress', format: (object: Restitution) => object.receptionPlaceAddress ?? '-' },
  { key: 'storagePlace', format: (object: Restitution) => object.storagePlace ?? '-' },
  {
    key: 'date',
    format: (object: Restitution) => {
      if (object.date) {
        return <FormattedDate value={`${object.date}Z`} year="numeric" month="numeric" day="numeric" hour="numeric" minute="numeric" />
      }
      return '-'
    },
  },
]

export const vehicle: Array<config> = [
  {
    key: 'brand',
    format: (object: Restitution) => object.brand,
  },
  {
    key: 'color',
    format: (object: Restitution) => object.color,
  },
  {
    key: 'model',
    format: (object: Restitution) => object.model,
  },
  {
    key: 'nbPlaces',
    format: (object: Restitution) => `${object.nbPlaces}`,
  },
  {
    key: 'chassis',
    format: (object: Restitution) => object.chassis,
  },
  {
    key: 'firstRegistrationDate',
    format: (object: Restitution) => object.firstRegistrationDate,
  },
  {
    key: 'licensePlate',
    format: (object: Restitution) => object.licensePlate,
  },
  {
    key: 'type',
    format: (object: Restitution) => object.type,
  },
  {
    key: 'fuel',
    format: (object: Restitution) => object.fuel,
  },
]
