import React, { FormEvent, useState } from 'react'

import { Button, Dialog, Grid, TextField, Typography } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

type Props = {
  open: boolean
  onClose?: () => unknown
  onConfirm: (plate: string) => unknown
}

function PlateModal({ open, onClose, onConfirm }: Props) {
  const [plate, setPlate] = useState<string>('')
  const intl = useIntl()

  const onChangeSearch = (query: string) => {
    const cleanQuery = query.replace(/[-,\s]/g, '').toUpperCase()
    if (query.length > 6) {
      setPlate(`${cleanQuery.slice(0, 2)}-${cleanQuery.slice(2, 5)}-${cleanQuery.slice(5)}`)
    } else if (cleanQuery.length > 2) {
      setPlate(`${cleanQuery.slice(0, 2)}-${cleanQuery.slice(2)}`)
    } else {
      setPlate(cleanQuery)
    }
  }

  const onSubmit = (e: FormEvent<unknown>) => {
    e.preventDefault()
    onConfirm(plate)
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm">
      <Grid container p={2} pb={0}>
        <Typography variant="h5" fontWeight={600}>
          <FormattedMessage id="modal.plate.title" />
        </Typography>
        <Grid container alignItems="center" py={2} component="form" onSubmit={e => onSubmit(e)}>
          <Grid xs={12} container>
            <TextField
              value={plate}
              onChange={e => onChangeSearch(e.target.value)}
              label={intl.formatMessage({ id: 'label.licensePlate' })}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid xs={12} container pt={1} justifyContent="flex-end" alignItems="flex-end">
            <Button type="submit" variant="contained" onSubmit={e => onSubmit(e)} disabled={!plate}>
              <FormattedMessage id="button.confirm" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default PlateModal
